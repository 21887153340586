.RolesPermission {
    font-size: 14px !important;
    color: $primary !important;
    .addUser_btn{
        background: $button;
        color: $white;
        border: 0;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, .4);
        font-size: 14px;
        max-height: 32px;

        &:hover {
            border: 0;
            transform: translateY(-1px) !important;
            background: #05304A;
        }
    }
    
    .form-text {

        font-size: 14px !important;
        color: $primary !important;
    }

    .selectAll.form-check {
        display: flex;
        align-content: r;
        text-align: right;
        justify-content: flex-end;

        label {
            padding-left: 10px;
            min-width: 70px;
        }
    }

    .form-check-input {
        border: solid 1px #94A3B8 !important;
        cursor: pointer;
    }
    .container {
        max-width: 100%;
    }



    /* sort and remove */

    

    .rolesHeading {
        display: flex;
        align-items: center;
        gap: 30px;
        padding: 2px 10px;
    }

    .permissionHeading {
        display: flex;
        justify-content: space-between;
        margin: 4px 9px -12px;
    }

    .permissionContent {
        display: flex;
        // justify-content: space-between;
        padding: 10px 5px 10px 5px;
        gap: 100px;
        border-top: solid 1px #E2E8F0;
        white-space: nowrap;
        width: 100%;
        .permissionName {
            width: 25%;
        }
    }

}
.permissionBtn {
    display: flex;
    justify-content: flex-end;
    .addUser_btn{
        background-color: $button;
    }
}
.statusActive {
    color: #1DC976;
    font-size: 13px;
    font-weight: 600;
}
.statusPending {
    color: #CFA044;
    font-size: 14px;
    font-weight: 600;
}
.role-permissin-sec{
    width: 100%;
    overflow-y: auto;
}
// css for the tooltip
.tooltip-trigger {
    cursor: pointer;
}

/* Example CSS to give each row a different text color for the Permissions Table */
.text-color-0 {
    color: red; /* Change this to your desired text color */
  }
  
  .text-color-1 {
    color: green; /* Change this to your desired text color */
  }

  .text-color-2 {
    color: rgb(223, 156, 33); /* Change this to your desired text color */
  }
  
  .text-color-3 {
    color: blue; /* Change this to your desired text color */
  }
  .text-color-4 {
    color: rgb(178, 29, 192); /* Change this to your desired text color */
  }
  .text-color-5 {
    color: rgb(129, 129, 207); /* Change this to your desired text color */
  }
  .text-color-6 {
    color: rgb(38, 245, 228); /* Change this to your desired text color */
  }
  .text-color-7 {
    color: rgb(1, 1, 3); /* Change this to your desired text color */
  }
  .text-color-8 {
    color: rgb(158, 235, 15); /* Change this to your desired text color */
  }
  
  /* Add more text-color classes as needed */
  