@import "../styles/variable";
body {
	background: #f4f5fb !important;
	font-size: 14px;;
}



/* loginPage */
.loginPage {
	background: gainsboro;
	height: 100vh;
	.eyeIcon {
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
		svg{
			height: 20px;
			width: 20px;
			color: #0F172A;
		}
	}
	.loginLeft {
		background: #13202D;
		height: 100vh;
		border-radius: 0% 10% 10% 0%;

		.highlightedBox {
			color: #fff;
			padding: 100px 40px 0px;

			p {
				color: #fff;
				font-weight: 600;
			}
		}
		footer {
			background: none;
			border: 0;

			.brandLogo {
				img {
					width: 75px;
					position: absolute;
					bottom: 50px;
					left: 51px;
				}
			}

		}
	}
	.loginLoader.spinner-border.text-light {
		height: 20px;
		width: 20px;
	}
	.raceLoginBox {
		width: 480px;
		padding: 60px 30px;
		margin: 0px auto;
		background: #fff;
		border: solid 1px #94A3B8;
		border-radius: 8px;
        
        .error {
			color: red;
			font-size: 14px;
			padding: 12px;
		}
		.success {
			color:green;
			font-size: 14px;
			padding: 12px;
		}

		.raceBtn{
			// background:#13202D;
			// color: $white;
			// border: 0;
			// box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, .4);
			// font-size: 16px;
			width: 100%;
	
			&:hover {
				border: 0;
				// transform: translateY(-1px) !important;
			}
	
		}
		.forget{
			cursor: pointer;
		}
		.otp_text {
			display: flex;
			justify-content: center;

			input {
				border: #94A3B8 solid 1px;
				padding: 16px;
				border-radius: 6px;
				width: 40px !important;
				height: 40px !important;
			}
		}

	}

}

/* Loader styles */
.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
  }
  
  .loader {
	border: 4px solid rgba(255, 255, 255, 0.3);
	border-top: 4px solid #3498db;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }
  