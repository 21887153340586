.navbarFixed {
  position: fixed !important;
  width: 100%;
  z-index: 9;
}
nav {
  background: $primary;
  color: $body;

  .form-text {
    color: $body;
    &:hover {
      color: rgb(199, 199, 199);
    }
  }
}

/* below class will be change once get right solutions */
.css-dip3t8 {
  background: none;
}
.css-1wvake5.ps-collapsed {
  .ps-menuitem-root {
    width: 78px;
    .ps-menu-icon {
      margin-right: 0;
      width: 100%;
      min-width: 30px;
    }
  }
}
/* end */
.profileSection {
  display: flex;
  align-items: center;
  gap: 17px;
  cursor: pointer;
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent;
  }

  svg {
    height: 17px;
    width: 17px;
  }

  .notifyAlert {
    background: red;
    height: 8px;
    width: 8px;
    border-radius: 13px;
    position: absolute;
    right: 1px;
    top: 5px;
  }

  .menu_icon {
    position: relative;
  }

  img {
    height: 30px;
    width: 30px;
  }

  .avatar {
    position: relative;
  }

  .onlineShow {
    height: 10px;
    width: 10px;
    border-radius: 14px;
    background: #65ad20;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  .profile_section.dropdown {
    button {
      background: linear-gradient(
        90deg,
        #b37a56,
        #fbcba7 32.29%,
        #c38a79 71.35%,
        #945b3b 95.31%
      ) !important;
      outline: 1px solid #945b3b !important;
      border-radius: 10px !important;
      --bs-btn-border-color: auto;
      height: 31px;
      width: 32px;
      display: none;
    }

    .dropdown-menu.show {
      display: block;
      margin-left: -160px;
      margin-top: 20px;
      padding: 1px 2px;
      background: #05304a;

      .dropdown-item {
        color: #fff !important;
        background: #05304a !important;
        font-size: 13px !important;
        line-height: 2;
        display: flex;
        align-items: center;
        gap: 16px;

        &:hover {
          background: #0f172a !important;
          border-radius: 20px;
        }
      }
    }

    .dropdown-menu-header.show {
      display: block;
      margin-left: -160px;
      margin-top: 20px;
      padding: 1px 2px;
      background: #ffff !important;
    }
  }
}

.logoSection {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 15px;

  .race-logo {
    color: #c3ab7d;
    cursor: pointer;
  }
}

.logoSection1 {
  display: flex;
  align-items: center;
  gap: 11px;
  padding-left: 15px;

  .race-logo {
    color: #c3ab7d;
    cursor: pointer;
  }
}

form.headerContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

aside {
  .active-tab {
    // background-color: #0F172A;
    background-color: #032744;
    border-radius: 20px;
    // max-width: 94%;
    // margin: 0px auto;
  }

  

  nav {
    background: none;

    ul {
      li {
        position: relative;
        margin-bottom: 4px;
        a {
          font-size: 14px;
        }
      }
    }
  }

  .race-logo,
  .race-logo1 {
    cursor: pointer;
    color: #e3d3aa;
    font-size: 24px;
  }

  // .css-dip3t8 {
  //     background: $secondary;
  //     padding: 15px 10px;
  //     -webkit-border-top-right-radius: 20px;
  //     -webkit-border-bottom-right-radius: 20px;
  //     -moz-border-radius-topright: 20px;
  //     -moz-border-radius-bottomright: 20px;
  //     border-top-right-radius: 20px;
  //     border-bottom-right-radius: 20px;

  //   .ps-menu-button:hover {
  //         background-color: $primary;
  //         border-radius: 8px;
  //     }
  // }
  // .css-z5rm24{
  //     background: transparent;
  // }
  // .css-1cuxlhl {
  //     position: absolute;
  //     right: 10px;
  //     top: 10px;
  // }
  /* end */
}

nav.fixed {
  position: sticky;
  top: 0;
  z-index: 999;
}
aside {
  .ps-menu-root {
    &.SideBar {
      max-height: calc(100vh - 58px);
      position: fixed;
      top: 58px;
      max-width: 240px;

      &::-webkit-scrollbar {
        width: 8px;
      }
      ul {
        max-height: calc(100vh - 58px);
        overflow: auto;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
          
        }
      }
    }
  }
}
.SideBar-wrap {
  .ps-sidebar-container {
    background-color: #0f172a;
    // -webkit-border-top-right-radius: 16px;
    // -webkit-border-bottom-right-radius: 16px;
    // -moz-border-radius-topright: 16px;
    // -moz-border-radius-bottomright: 16px;
    // border-top-right-radius: 16px;
    // border-bottom-right-radius: 16px;
  }
  // remove border radius bcoz remove margin top from aside
}

.SideBar {
  ul {
    li {
        &.ps-submenu-root {
            padding: 5px;
        }
      a {
        background: transparent;

        &:hover {
          background: #0f172a !important;
          border-radius: 20px;
          // width: 96%;
          // margin: 0px auto;
        }
      }
      &.active { 
        a {
        background: #0f172a !important;
        border-radius: 20px;
        // width: 96%;
        // margin: 0px auto;
        }
      }

      .ps-menu-button {
        padding-left: 20px;
      }

      .ps-menu-icon svg {
        width: 20px;
        height: 20px;
      }
    }
    ul {
      li {
        padding: 0px 10px 0px 15px;
      }
    }
  }

  .ps-submenu-content {
    background: none;
    box-shadow: none;
  }
}

.ps-sidebar-root .ps-menu-icon {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ps-sidebar-root.ps-collapsed .ps-menu-icon svg {
  width: 24px;
  height: 24px;
}

.ps-sidebar-root {
  position: fixed;
  // margin-top: 58px;
  .ps-collapsed {
    .ps-menu-icon {
      font-size: 20px;
    }
  }
}

// .SideBar > ul > li.ps-menuitem-root > a {
//     padding-left: 5px;
// }
