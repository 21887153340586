/* dashboard page */
.dashboardMain {
    .userIcon {
        width: 24px;
        height: 24px;
    }

    .w-4 {
        padding: 0;
        white-space: nowrap;
    }

    .h6,
    h6 {
        margin: 0px;
        font-size: 14px;
    }

    .card1 {
        background-image: linear-gradient(#F06966, #FAD6A6);
        border-color: #F06966;
        color: #fff !important;
    }

    .card2 {
       
        background-image: linear-gradient(#2F80ED, #B2FFDA);
        border-color: #F06966;
        color: #fff !important;
    }

    .assignee_text.row {
        align-items: center;
    }

    .graphSection.card {
        margin: 30px 4px;

        .piechart.col {
            width: 24%;
            display: flex;
            justify-content: center;

            canvas {
                max-height: 360px !important;
                padding: 0px 4px;
                margin: 34px 5px;
            }
        }
    }
}

/* dashboard page end */
.payment-date-sec  .react-datepicker-wrapper input {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    appearance: none;
    background-color: #fff;
    border:1px solid #dee2e6;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 8px 15px;
    width: 100%;
    cursor: pointer;
}
.payment-date-sec  .react-datepicker-wrapper{
    width: 100%;
}
.datepickericon {
    position: absolute;
    right: 10px;
    top: 50%;
    width: 20px;
    height: 24px;
    transform: translateY(-50%);
    display: inline-block;
    z-index: 1;
}
.datepicker-box {
    position: relative;
    z-index: 2;
}
.datepickericon svg {
    width: 20px;
    height: 20px;
    fill: #6c757da6;
}
/* mastar page css*/

.investmentConfigure {
    .headTop {
        display: flex;
        align-items: baseline;
        gap: 8px;
    }

    .headTopText {
        font-weight: 500;
    }

    .exclamation {
        cursor: pointer;
        color: #06487e87;
    }

    .addmemberBox {
        display: flex;
        justify-content: space-between;
        padding-top: 22px;

    }

    .mainContent {
        padding-top: 20px;

        svg {
            cursor: pointer;
            color: red;
            height: 21px;
            width: 21px;
            margin-left: 10px;
        }
    }

    .mainRow {
        align-items: center;
    }

    .addBtn {
        display: flex;
        justify-content: flex-end;
    }

    .leadCheck {
        display: flex;
        align-items: center;
        gap: 18px;
    }

    // padding: 20px;
    label.form-label {
        font-size: 14px;
        color: #000;
        font-weight: 500;
    }

    .errorText {
        display: flex;
        gap: 111px;
    }

    .votingSection {
        max-width: 140px;
    }

    .input-group {
        input {}
    }

    .form-check-input[type=radio] {
        border-radius: 50%;
        outline: 1px solid darkgray;
        cursor: pointer;
    }
    label.form-check-label{
        cursor: pointer;
    }

    .checkboxes {
        display: flex;
        gap: 24px;
        margin: 36px 6px 0px 0px;
    }

    .membersBox {
        display: flex;
        align-items: center;
        gap: 33px;
    }

    .membersSection {
        display: flex;
        gap: 25px;
    }

    .amountSection {
        // display: flex;
        // gap: 30px;
        align-items: center;
    }


    .priceRange {
        display: flex;
        gap: 18px;
        align-items: flex-end;
        margin-bottom: 19px;

        input {}
    }

    svg.tooltipIcon {
        cursor: pointer;
        color: dimgray;
    }

    .closeBox {
        display: flex;
        gap: 19px;
        width: 100%;
    }

    .members {
        width: 100%;
    }


    .topHead {
        display: flex;
        justify-content: space-between;
    }

    .close-btn {
        position: absolute;
        top: 2px;
        right: 3px;
    }

    .rangeHead {
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .typeGroupBox {
        display: flex;
        align-items: baseline;
        gap: 13px;
    }

    .membersTypeBox {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .closeIconfield {
        height: 24px;
        width: 24px;
        color: red;
        cursor: pointer;

        &:hover {
            color: darkred;
        }
    }

    .addIconfield {
        height: 40px;
        width: 45px;
        color: green;
        cursor: pointer;

        &:hover {

            color: darkgreen;

        }

    }


    .cardBox {
        border: solid 1px #ccc;
        border-radius: 8px;

        h5 {
            margin-bottom: 0;
            border-bottom: solid 1px #ccc;
          padding: 10px;
          background: #06487e;
          color: #fff;
          border-radius: 8px 8px 0px 0px;
        }

    }

.cardbox-inner-sec{
    padding: 20px 10px 20px 10px;
    background: #cbd5e147;

}


    .nav.nav-underline {
        display: flex;
        gap: 63px;
        width: 100%;
        border-bottom: solid 1px #ccc;

        button {
            background-color: #ffff !important;
        }
    }

    .dicConfigurationBox.card {
        padding: 11px 22px;
        margin-top: 26px;
        margin-bottom: 18px;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, .4);
    }

    .nav-underline .nav-link {
        color: grey !important;
        background-color: #ffff !important;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item {
        button {
            color: #396D8D !important;
            font-weight: 500 !important;
            background-color: #ffff !important;
            pointer-events: none;
        }
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        border-bottom: 2px solid #396D8D;
    }

    .saveBtn {
        display: flex;
        gap: 12px;
        justify-content: flex-end;
        ;

    }

    .dicHead {
        display: flex;
        flex-direction: column;
        line-height: 2;
    }

    .addRangee {

        height: 32px !important;
        max-width: 100px !important;
    }

    .range {
        display: flex;
        justify-content: space-between;

        label {
            color: darkgrey;
        }
    }


    .memberBox {
        select {
            max-width: 115px;
        }
    }

    .headText {
        display: flex;
        gap: 124px;
    }

    .searchWrapper {
        span {
            background-color: $button;
        }
    }

}

.assetConfigure {
    .assetField {
        max-width: 50%;
        font-size: 14px;
    }

    .investType {
        margin-top: 20px;

        .assetType {
            display: flex;
            gap: 51px;
        }
    }

    select {
        font-size: 14px;
    }

    .input-group {
        input {
            font-size: 14px;
        }
    }

    .form-check-input[type=radio] {
        border-radius: 50%;
        outline: 1px solid darkgray;
    }

    .checkboxes {
        display: flex;
        gap: 24px;
        margin: 36px 6px 0px 0px;
    }

    .amountSection {
        display: flex;
        gap: 30px;
        align-items: center;
    }

    label {
        font-size: 12px;
    }

    .priceRange {
        margin: 14px 0px 0px 0px;

        input {
            font-size: 14px;
        }
    }

    svg.tooltipIcon {
        cursor: pointer;
        color: dimgray;
    }

    .closeBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dicConfiguration.row {
        outline: 1px solid lightgrey;
        padding: 29px 0px;
        border-radius: 9px;
        margin-bottom: 12px;

    }

    .rangeHead {
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .typeGroupBox {
        display: flex;
        align-items: baseline;
        gap: 13px;
    }

    .membersTypeBox {
        display: flex;
        flex-direction: column;
        gap: 6px;
        max-width: 245px;
    }

    .closeIconfield {
        height: 24px;
        width: 24px;
        color: red;
        cursor: pointer;

        &:hover {
            color: darkred;
        }
    }

    .addIconfield {
        height: 40px;
        width: 45px;
        color: green;
        cursor: pointer;

        &:hover {

            color: darkgreen;

        }

    }

    .nav.nav-underline {
        display: flex;
        gap: 63px;
        width: 100%;
        border-bottom: solid 1px #ccc;

        button {
            background-color: #ffff !important;
        }
    }

    .dicConfigurationBox.card {
        padding: 11px 22px;
        margin-top: 26px;
        margin-bottom: 18px;
    }

    .nav-underline .nav-link {
        color: grey !important;
        background-color: #ffff !important;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item {
        button {
            color: #396D8D !important;
            font-weight: 500 !important;
            background-color: #ffff !important;
            pointer-events: none;
        }
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        border-bottom: 2px solid #396D8D;
    }

    .saveBtn {
        display: flex;
        gap: 12px;
        justify-content: flex-end;
        ;

    }

    .dicHead {
        display: flex;
        flex-direction: column;
        line-height: 2;
    }

    .assetConfigure .addRangee {
        margin: 13px 0px;

    }

    .close-btn {
        display: flex;
        justify-content: flex-end;
    }

    .range {
        display: flex;
        justify-content: space-between;

        label {
            color: darkgrey;
            font-size: 13px;
        }
    }


    // .BtnBox {
    //     min-width: 104px;
    //     padding: 0px 0px 0px 0px;
    //     text-align: right;

    // }

    .memberBox {
        select {
            font-size: 14px;
            max-width: 115px;
        }
    }

    .headText {
        display: flex;
        gap: 124px;
    }


    button {
        background: $button;
        color: $white;
        border: 0;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, .4);
        font-size: 16px;
        padding: 8px 24px;

        &:hover {
            border: 0;
            transform: translateY(-1px) !important;
            background: #0F172A;
        }

        @media screen and (max-width: 1366px) {
            padding: 6px 20px;
            font-size: 14px;

        }


        &:hover {
            border: 0;
            transform: translateY(-1px) !important;
        }

    }

    .searchWrapper {
        span {
            background-color: $button;
        }
    }

}


.head {
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 15px;

    h6 {
        font-size: 14px;

        @media screen and (max-width: 1366px) {
            font-size: 10px;
            margin-bottom: 8px;
        }

        margin: 0px;
    }

    .filterSearch {
        justify-content: space-between;

    }

    h4 {
        font-size: 20px;
        margin: 0;
        padding: 0;
    }

    input {
        // min-width: 460px;
        border: solid 1px $border;
        padding: 8px 15px;
        margin-bottom: 10px;

        @media screen and (max-width: 1366px) {
            min-width: 200px;

        }
    }

    select {
        // min-width: 360px !important;
        margin-bottom: 10px;

        @media screen and (max-width: 1366px) {
            min-width: 200px !important;

        }
    }

    .ps-collapsed {
        a {
            color: red;
            font-size: 40px;
        }
    }


    .form-floating>label {}

    .form-floating>.form-control,
    .form-floating>.form-control-plaintext,
    .form-floating>.form-select {
        height: calc(48px + calc(var(--bs-border-width) * 2));
        min-height: calc(2rem + calc(var(--bs-border-width) * 2));
        line-height: 1;
    }

    .labeHeight label {
        padding: 0.2rem .75rem !important;
    }

    .downloadBox {
        display: flex;
        padding: 0px 0px 0px 0px;
        justify-content: flex-end;
        ;

        .download {
            color: #396D8D;
            font-size: 12px;
            cursor: pointer;
            background: #CBD5E1;
            padding: 4px 8px;
            border-radius: 4px;
            border: solid 1px #f3f3f3;
            text-decoration: none;
            margin-right: 10px;


            &:hover {
                background: #aab6c5;
            }
        }

        a.download svg {
            font-size: 20px;
            margin-right: 5px;
            text-decoration: none;
        }

    }



}

.iCons {
    cursor: pointer;
    color: grey;
}

.gridData {
    margin-top: 40px;
}

/* master page css end */

/* user management css */


.main_container {
    display: flex;
}

.addUser_btn {
    background-color: $button;
}

.main_content {
    width: 100%;
    max-width: calc(100% - 250px);
}

.main_container .ps-collapsed+.main_content {
    max-width: calc(100% - 80px);
}

.changeStatus {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        background: grey;
        color: $white;
        border: 0;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, .4);
        font-size: 14px;
        max-height: 32px;

        &:hover {
            border: 0;
            transform: translateY(-1px) !important;
            background: grey;
        }

    }

    .btn-text-center {
        display: flex;
        align-items: center;
    }
}


.inReviewStatus {
    color: #0945eb;
    font-size: 14px;
    font-weight: 500;
}

.liveStatus {
    color: #0a8310;
    font-size: 14px;
    font-weight: 500;
}

.approveStatus {
    color: #2ea6eb;
    font-size: 14px;
    font-weight: 500;
}

.rejectedStatus {
    color: #f73333;
    font-size: 14px;
    font-weight: 500;
}

.reviewedStatus {
    color: #170acc;
    font-size: 14px;
    font-weight: 500;
}




.notFound {
    display: flex;
    justify-content: center;
    margin-top: 20%;
}

.professionSubmit {
    button {
        background: $button;
        color: $white;
        border: 0;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, .4);
        font-size: 16px;
        padding: 8px 24px;

        &:hover {
            border: 0;
            transform: translateY(-1px) !important;
            background: #0F172A;
        }
    }
}

.addProfession {
    .dataTable {
        margin-top: 12px;
    }

    .professionSubmit {
        button {
            background: $button;
            color: $white;
            border: 0;
            box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, .4);
            font-size: 16px;
            padding: 8px 24px;

            &:hover {
                border: 0;
                transform: translateY(-1px) !important;
                background: #0F172A;
            }
        }
    }

    .profession {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    label {
        font-size: 15px;
    }
}


.addProfessionModal .ModalTitleText {
    padding: 6px;
}

.addProfessionModal .AddProfessionData {
    display: flex;
    gap: 10px;
}

.addProfessionModal .ModalTitleText-note {
    padding: 6px;
    font-size: 13px;
    font-weight: 500;
}

.addProfessionModal .helperText {
    padding: 6px;
    font-size: 13px;
    margin-bottom: 2px;
    color: red;
}

.addProfessionModal .profession-value {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.addProfessionModal .inserted-professions {
    font-size: 18px;
    margin-bottom: 4px;
    font-weight: 500;
    margin-left: 5px;
}

.addProfessionModal .profession-value .remove-btn {
    cursor: pointer;
    color: red;
    height: 21px;
    width: 21px;
    margin-left: 5px;
}

/* UserMangement */
.mainWrapper {
    // padding: 20px 15px;
    padding: 25px 20px 20px 30px;
    

    .mainHeader {
        display: flex;
        flex-direction: column;
        text-align: left;

        h4 {
            font-size: 20px;
            margin: 0px;
            text-align: left;
        }
    }

    .card {
        background-clip: padding-box;
        box-shadow: 0 12px 6px 0 rgba(67, 89, 113, .12);
        padding: 12px 15px;
        border: 0px;
        border-radius: 6px;
        margin-top: 15px;
        // min-height: 471px;
        // height: 100%;
        // min-height: 200px;
        // padding: 15px;
        height: 100%;

        .cardHeadText {
            display: flex;
            justify-content: space-between;
        }

        .UserManagementHeadPart {
            .head {
                width: 100%;
                padding-bottom: 0;
                margin-bottom: 15px;

                h6 {
                    font-size: 14px;

                    @media screen and (max-width: 1366px) {
                        font-size: 10px;
                        margin-bottom: 8px;
                    }

                    margin: 0px;
                }

                .filterSearch {
                    justify-content: space-between;

                    .label {
                        font-size: 12px;
                    }
                }

                h4 {
                    font-size: 20px;
                }

                input {
                    /*  min-width: 460px;
                        border: solid 1px $border;*/

                    @media screen and (max-width: 1366px) {
                        /*  min-width: 260px;*/

                    }
                }

                select {
                    /*   min-width: 360px !important;*/
                    border: 1px solid #ccc;
                    padding: 8px 15px;


                    @media screen and (max-width: 1366px) {
                        /*  min-width: 200px !important;*/

                    }
                }

                .form-floating>label {
                    font-size: 12px;
                }

                .form-floating>.form-control,
                .form-floating>.form-control-plaintext,
                .form-floating>.form-select {
                    height: calc(48px + calc(var(--bs-border-width) * 2));
                    min-height: calc(2rem + calc(var(--bs-border-width) * 2));
                    line-height: 1;
                }

                .labeHeight label {
                    font-size: 12px;
                    padding: 0.2rem .75rem !important;
                }

                .downloadBox {
                    display: flex;
                    padding: 0px 0px 0px 0px;
                    justify-content: flex-end;
                    ;

                    .download {
                        color: #CBD5E1;
                        font-size: 12px;
                        cursor: pointer;
                        background: #396D8D;
                        padding: 8px 16px;
                        border-radius: 6px;
                        // border: solid 1px #f3f3f3;
                        text-decoration: none;
                        // margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:hover {
                            background: #aab6c5;
                        }
                    }

                    a.download svg {
                        font-size: 20px;
                        margin-right: 5px;
                        text-decoration: none;
                    }


                }


            }
        }

        .iCons {
            display: flex;
            gap: 14px;

            a {
                color: $icon;
                font-size: 18px;
                cursor: pointer;

                &:hover {
                    color: $button;
                    cursor: pointer;
                }

                span {
                    font-size: 12px;
                    padding-left: 8px;
                }
            }
        }
        .icon-gap {
            margin-right: 25px; 
        }
        .form-switch {
            input {
                width: 30px;
                padding: 8px;
                cursor: pointer;
            }
        }
.dashboard-card-sec .card{
cursor: pointer;
}
        .form-check-input:checked {
            background-color: $button;
            border-color: $button;
        }
    }

    .form-control {
        border: 1px solid #ccc;
    }

    .dataTable {
        // @media screen and (max-width: 1024px) {
        //     overflow: scroll;
        // }

        div {
            font-size: 16px;
            // font-weight: 300;

            @media screen and (max-width: 1366px) {
                // overflow: visible;
                // min-height: 40px;
                // line-height: 2;

                .form-switch {
                    padding-left: 2.5em;
                    min-height: 40px;
                    line-height: 40px;
                    text-align: center;
                    margin: 0px;
                    vertical-align: middle;
                    display: flex;
                    align-items: center;
                }


            }

        }

        .h6,
        h6 {
            font-size: 16px;
            margin: 0px;
            font-weight: bold;

            @media screen and (max-width: 1366px) {
                font-size: 14px;

            }
        }

    }

    //   .actionButton{
    //         margin-right: 80px;
    //         }


    .userNameHeading {
        display: flex;
        flex-direction: column;
        gap: 6px;
        // align-items: center;
        // padding-top: 8%;

        img {
            height: 150px;
            width: 150px;
        }

        h5 {
            font-size: 16px;
            margin: 0px;
        }

        .form-text {
            font-size: 12px;
        }
    }

    .userNameBlock {
        padding: 0px 37px;
        border-radius: 9px;

        .profileDetail {
            display: flex;
            gap: 20px;
            padding: 0 1px;

            .userDetail {
                display: flex;
                flex-direction: column;
                gap: 7px;

                label {
                    font-size: 16px;

                    @media screen and (max-width: 1366px) {
                        font-size: 14px;
                    }
                }
            }

            .userData {
                display: flex;
                flex-direction: column;
                font-size: 16px;
                font-weight: 600;

                .form-text {
                    font-size: 16px;
                }

                @media screen and (max-width: 1366px) {
                    font-size: 14px;

                    .form-text {
                        font-size: 14px;
                    }
                }
            }

            .userData.projectValue {
                gap: 10px !important;
            }
        }

    }
}

/* end user management css */


/* statusBtnBox dicmeber css */
.actionBtn {
    color: #fff;
    border: 0;
    box-shadow: none;
    font-size: 12px;
    padding: 0px 10px;
    border-radius: 30px;
    height: 24px;
    line-height: 17px;
    vertical-align: middle;
    display: inline-block;

    &:hover {
        box-shadow: none;
    }

    &.danger {
        background-color: red;
    }

    &.success {
        background-color: green;
    }
}

.add-field-btn {
    margin-top: 35px !important;
    text-align: end;
}

.modal-footer {
    border: none !important;
}

/* end dicmeber css*/

/* common css */

.BtnBox {
    margin-right: 10px;

    button {
        background: $button !important;
        color: $white;
        border: 0;
        padding: 9px 15px;
        font-weight: 500;

        &:hover {
            border: 0;
            box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, .4);
            cursor: pointer;
        }
    }


    a {
        background: #06487E !important;
        color: #fff;
        border: 0;
        padding: 8px 15px;
        font-weight: 500;
        text-decoration: none;
        border-radius: 4px;

        &:hover {
            border: 0;
            padding: 9px 15px;
            font-weight: 500;
            text-decoration: none;
            border-radius: 4px;

            &:hover {
                border: 0;
                box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, .4);
                cursor: pointer;

            }
        }
    }


}
.react-datepicker-wrapper {
    width: 100%;
    & + .react-datepicker__tab-loop {
        .react-datepicker-popper {
            z-index: 3;
        }
    }
}
.fl {
    float: left;
}

.text-right {
    text-align: right;
}

.btn-primary {
    background: $button !important;
    color: $white;
    border: 0 !important;
    padding: 10px 15px;
    font-weight: 500;

    &:hover {
        border: 0;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, .4);

    }

}

.ModelForm textarea {
    // margin-bottom: 15px;
}

select.form-select {
    padding: 8px -2px;
    cursor: pointer;
}

input.form-control ,select.form-select{
    padding: 8px 15px;
    cursor: pointer;
}
select.form-select{
    padding-right: 35px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dicStatus.approved {
    font-weight: 600 !important;
    color: green !important;

}

.dicStatus.rejected {
    color: red !important;
    font-weight: 600 !important;
}

.dicStatus.newUser {
    color: rgb(199 176 48) !important;
    font-weight: 600 !important;
}

.breadcrumb {
    display: flex;
    justify-content: flex-end;
    ;
    margin: 0px;

    ol {
        margin: 0;
        padding-top: 4px;
    }

    nav {
        background: none;
    }
}

.breadcrumb.headingText {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 12px;
}

.breadcrumb {
    a {
        color: $secondary !important;
        font-weight: 500;
        text-decoration: none;
    }
}

.dicConfig {
    .card {
        height: 100%;
    }

}

.dicBtnBox {
    // max-width: 50%;
    display: flex;
    gap: 13px;
    padding: 12px 0px;
    justify-content: flex-end;
    ;
}

.roleTable {
    span {
        background: rgb(235, 235, 235);
        padding: 2px 8px;
        margin-right: 10px;
        border-radius: 30px;
    }
}


/* common css end */

/* user details */
.tabstyle {
    display: flex;
    gap: 63px;
    width: 100%;
    border-bottom: solid 1px #ccc;
}

.add-members-div {
    display: flex;
    flex-direction: column-reverse;
}


.range-tabs {
    .nav-tabs {
        .nav-item {
            border-bottom: 2px solid red !important;
        }

        .nav-link {
            border: 2px solid red !important;
        }
    }
}

.form-check-input:focus {
    border-color: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
}
// start css of adminpanel/langingpageConfiguration
.admin-custom-card {
    box-shadow: none !important;
    background-color: transparent !important;
}
.card-img-sec {
    border: 1px dashed gray;
    height: 290px;
    max-height: 290px;
}
.card-img-sec  .full-section {
    height: 100%;
}
.card-img-sec  .full-section img,.card-img-sec  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.last-section-left-box .card {
    margin-top: 20px;
    flex:0 0 49%;
}
.admin-custom-card .container {
    max-width: 100%;
}
.dicModal{
    width: 100vw !important;
}
.fade.modal-backdrop.show {
    height: 100%;
}
.form-check .form-check-input{
    cursor: pointer;
}
.user-mobile-lable{
    display: none;
}
.modal-body button {
    font-weight: 500;
    line-height: 20px;
    // margin-top: 15px !important;
}

.mainWrapper .dataTable div svg {
    width: 16px;
    height: 16px;
    flex: 0 0 auto;
}

.ps-collapsed.SideBar-wrap .ps-submenu-content {

    display: none !important;
}

//  for calender Grid

.calendar-box {
    border: 1px solid #ccc;
    padding: 10px;
    width: 407px;
    margin-top: 10px;
}
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr)); /* Adjust the width of columns */
    gap: 5px; /* Adjust the gap between cells */
  }
  
  .calendar-number {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    cursor: pointer; /* Add cursor pointer to indicate clickable */
  }
  
  .selected {
    background-color: #007bff;
    color: white;
  }
  
// css for calender
  .input-with-icon {
    position: relative;
  }
  
  .form-control {
    padding-right: 40px; /* Adjust the padding to make space for the icon */
  }
  
  .calendar-icon {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the right position as needed */
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .repayment-configur-modal-body {
    background: rgba(203, 213, 225, 0.2784313725);
}

.text-danger {
    position: absolute;
    font-size: 13px;
    line-height: 14px;
}
 .dic-congig-card-inner-sec{
    padding-bottom: 40px !important;
 } 
 .error-div {
    position: relative;
}
.role-permission-tag-sec{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.role-permission-tooltip-tag{
    margin: 5px 10px 5px 0px !important;
}
.project-detail-tab-sec .custom-tabs {
    background: #fff !important;
    border-radius: 8px;
padding: 0px;
}
.project-detail-tab-sec .custom-tabs button {
    width: 100%;
    text-align: center !important;
    padding: 10px 15px;
    border: 1px solid #dee2e6 !important;
    border-radius: unset !important;
}
.project-detail-tab-sec {
    padding: 12px 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
    display: flex;
    flex-wrap: wrap;

}

.fund-type-info{
    color: red;
    padding-top: 15px;
}

@media screen and (max-width: 1199px) {
    .last-section-left-box .card {
        margin-top: 20px;
        flex:0 0 100%;
    }
    .last-col-right-sec {
        justify-content: start !important;
    }
    .loginPage .loginLeft .highlightedBox h1 {
        font-size: 30px;
    }
    
}
@media screen and (max-width: 991px) {
    .mainWrapper .head .row {
        margin: 0;
    }
    .dashboardMain {
        .graphSection.card {
            .piechart.col {
                canvas {
                    height: 267px !important;
                }
            }
        }
    }
    .tab-inner-col{
        flex: 0 0 auto;
        width: 100% !important;
      }
      .tabSectionMain .leftMenu{
        border-bottom: 1px solid #ccc;
        border-right: unset !important;
      }
    .loginPage .loginLeft,.loginPage{
        height: 100%;
        border-radius: 0px;
    }
    .loginPage .loginLeft {
        margin-bottom: 20px;
        position: relative;
    }
    .loginPage .loginLeft .highlightedBox {
        padding: 150px 30px 35px;
    }
    .loginPage .loginLeft footer .brandLogo img {
        width: 75px;
        position: absolute;
        bottom: 0;
        left: 0;
        top: 15px;
        right: 0;
        margin: 0px auto 15px;
    }
    .loginPage .loginLeft .highlightedBox h1 {
        font-size: 25px;
    }
    
    .mainWrapper .userNameBlock {
        padding: 0px 10px;
    }
    .breadcrumb.headingText{
        padding: 0px 10px;
    }
    // sidebar css start
    .css-1wvake5.ps-collapsed {
        width: 60px  !important;
        min-width: 60px !important;
        height: 100% !important;
       .ps-menuitem-root{
            width: 55px;
            .ps-menu-icon {
                margin-right: 0;
                width: 100%;
                min-width: 25px;
            }
        }
    }
    aside.SideBar-wrap {
        height: 100% !important;
        position: absolute;
        left: 0;
    }
    .main_container .ps-collapsed + .main_content ,.main_content{
        max-width: calc(100% - 60px);
        margin-left: auto;
    }
    .main_container {
        display: block;
        position: relative;
    }
    .SideBar ul li .ps-menu-button {
        padding: 0px 10px;
    }

    // sidebar css end
    .createpost-container .card form input[type=file]{
        width: 50%;
    }
    .mainWrapper .dataTable  .rdt_TableRow .rdt_TableCell:last-child {
        min-width: 85px;
    }

    .react-tooltip {
        position: absolute !important;
        margin-left: 10px; /* Ensure some spacing between sidebar and tooltip */
        z-index: 1000;     /* Keep the tooltip on top of other elements */
      }
}
@media screen and (max-width: 768px) {
    .project-detail-tab-sec .custom-tabs {
        width: 100% !important;
        margin-right: 0 !important;
    
    }
    .mainWrapper {
        padding: 25px 10px 20px 10px;
    }
    .rdt_TableHeadRow .rdt_TableCol {
        width: auto !important;
        max-width: 100% !important;
    }

    .createpost-container .card form input[type=file],.createpost-container .card form input[type=text] {
        width: 70%;
    }
    
    .admin-custom-card {
        padding: 0px !important;
    }
    .dicModal.modal {
        padding-left: 0px !important;
    }
    .dicModal .modal.show .modal-dialog {
        width: 95% !important;
    }
    .card-img-sec {
        height: 250px;
    }
    .card-bottom-sec .card-body,.last-section-left-box .card-body {
        padding: 0;
    }
    .dicModal .modal-dialog {
        --bs-modal-width: 95%;
    }
    .mainWrapper .card{
        min-height: unset !important;
    }
    .dashboardMain .graphSection.card {
        padding: 15px 10px;
    }
    .yearFilter {
        margin-bottom: 10px;
    }
    #header-admin-name {
        display: none;
    }
    .profileSection {
        gap: 10px;
    }
    .rdt_Pagination .MLcXO {
        padding: 4px !important;
    }
    .head .downloadBox{
        margin-top: 10px;
    }
    .mainWrapper .mainHeader h4 {
        font-size: 18px;
    }
    .lead-mem-lab{
        text-align: right;
    }
    .admin-custom-card.card input, .admin-custom-card textarea, .addUserContent input,.addUserContent select {
        margin-bottom: 15px;
    }
    .userdetail-content{
        padding-top: 10px;
    }
    .edituser-password-lable input{
        margin-bottom: 0px;
    }
    .addUserContent{
        padding: 20px 0px !important;
    }

    .checkbox-darkblue .form-check-input {
        border-color: darkblue;
    }
    
   
}
@media screen and (max-width: 568px) {
    .loginPage .loginLeft .highlightedBox {
        padding: 150px 10px 25px;
        text-align: center;
    }
    .loginPage .loginLeft .highlightedBox h1 {
        font-size: 22px;
    }
    .loginPage .raceLoginBox {
        padding: 30px 20px;
    }
    .loginPage .raceLoginBox form {
        padding-top: 25px !important;
    }
.mainWrapper .userNameBlock .profileDetail .userDetail.user-lable-detail {
    display: none;
}
.createpost-container .card form input[type=file] ,.createpost-container .card form input[type=text]{
    width: 100%;
}
.user-mobile-lable{
    display: block;
    font-weight: 500;
}
.mainWrapper .userNameBlock .profileDetail .user-content-detail .user-mobile-lable {
    font-size: 14px;
}
.mainWrapper .userNameBlock .profileDetail .user-content-detail label {
    font-size: 12px;
}
.RolesPermission .permissionContent .permissionName {
    width: auto;
}
}
@media screen and (max-width: 480px) {
    .mainWrapper .card .UserManagementHeadPart .head select,.mainWrapper .card .UserManagementHeadPart .head input {
        min-width: auto !important;
    }
    .modal-title{
        font-size: 19px !important;
    }
    .modal-body label{
        font-size: 14px !important;
    }
}
//end css of adminpanel/langingpageConfiguration