.addUserContent {
    padding: 30px 15px;
    .countryCode.col-md {
        max-width: 32%;
    }
   
}
.mb-3.submitUser {
    display: flex;
    justify-content: flex-end;
    padding: 7px 14px;
    .addUser_btn {
        background: $button;
        color: $white;
        border: 0;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, .4);
        font-size: 14px;
        max-height: 35px;

        &:hover {
            border: 0;
            transform: translateY(-1px) !important;
            background: #05304A;
        }
    }
}
.mb-4.roles.form-floating {
    max-width: 50%;
}
.col.content {
    display: flex;
    justify-content: center;
}
.col.profileData {
}

.tabHead.nav.nav-underline {
    display: flex;
    gap: 63px;
    width: 100%;
    border-bottom: solid 1px #ccc;
    white-space: nowrap;
    overflow-y: auto;
    flex-wrap: nowrap;
    padding-bottom: 7px;
}
.nav-underline .nav-link.active, .nav-underline .show>.nav-link {
    color: #396D8D !important;  
}
.nav-underline .nav-link {
    color: grey !important;
}

/* table KYC */

.projectContent {
    h6{
        font-size: 16px;
        margin: 0px;
    }
    width: 100%;
    div{
        font-size: 16px;
    }
    
    // Code for the kyc Header
    .kycHeader {
        text-align: center;
        display: flex;
        justify-content: space-between;
         margin-top:30px;
    }

// code for the kyc Card Section
    .cardSectionKyc{
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .cardSectionFaceProof .cards{
        cursor: pointer;
    }

    @media screen and (max-width: 1366px) {
        h6{
            font-size: 14px;
            margin: 0px;
        }
        div{
            font-size: 14px;
        }

    }
}

.portfolioHead{
    width: 100%;
    margin: 10px 0px;
    border-radius: 12px;
    .headLabel {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        line-height: 3;
    }
    .cardSection {
        gap: 16px;
        margin-bottom: 20px;
        .cards{
             
                background: #ebe1be;
                padding: 0;
                h3{
                    font-size: 24px;
                }
            
        }
    }
}
.iconBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #AEA06E;
    border-radius: 64px;
    color: #E9DEB9;
    float: right;
    padding: 10px;
}
.SideBar{
   .css-um1o6k {
        display: none;
    }
}

.carousel {
    width: 100%;
    cursor: pointer;
    li.react-multi-carousel-item.react-multi-carousel-item--active {
        width: 270px !important;
    }

.carouselCard.card{
    background: #05304A;
    color: #fff;
    margin-right: 10px;
    width: 100%;
    img{
        padding: 0;
        border-radius: 16px;
        height: 180px;
        width: 100%;
    }
    .cardHead {
        font-size: 16px;
        font-weight: 500;
    }
    .loanTag {
        background: #13202d;
        border-radius: 6px;
        color: #fff;
        padding:3px 5px;
        font-size: 12px;
        font-weight: 500;
    }
    .loanTagProcess {
        width: 34px;
        height: 20px;
        background: #22C55E;
        border-radius: 20px;
    }
    .footerHead {
        font-size: 15px;
        font-weight: 500;
    }
    .footerText {
        font-size: 12px;
        color: darkgrey;
    }
    .CardStatsBox{

        .loanTagProcess{
            margin-right: 10px;
        }
    }
   
} 

 


}