.blogPage {
    .tab-content {
        padding: 20px 0px 0px 0px;
    }
    .publishedText {
        background-color: lightblue;
        border-radius: 5px;
        padding: 0px 6px;
        font-size: 12px;
        color: #1c3d4b;
    }
    .card-body{
        padding: 0;
      
    }

    .draftText {
        background-color: $gold;
        border-radius: 30px;
        padding: 0px 10px;
        font-size: 12px;
        color: $primary;
    }

    .card-title {
        font-size: 16px;
    }
    .blogImg{
        position: relative;
        
    }
    .blogEdit {
        position: absolute;
        right: 18px;
        color: #396D8D;
        top: 22px;
        background-color: #fff;
        border-radius: 18px;
        padding: 5px 9px;
        cursor: pointer;
        &:hover {
            border: 0;
            transform: translateY(-1px) !important;
            background: #05304A;
        }
    }
}

.createpost-container {

    .card {
        .heading {
            font-size: 2.2rem;
            font-weight: bold;
            margin-bottom: 1rem;
        }
        /* input type file */
        form{
             label {
                font-size: 12px;
                font-weight: 500;
            }
    
             .img-preview {
                width: 18%;
                height: 18%;
                border-radius: 10px;
                margin-bottom: 1rem;
            }
    
             .img-preview img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
             .remove-image {
                background: #f44336;
                color: #fff;
                border-radius: 7px;
                cursor: pointer;
                font-size: medium;
                font-weight: 100;
                width: 24px;
                height: 24px;
                padding: 0px 0px 8px 4px;
                border-radius: 20px;
                font-size: 15px;
                position: absolute;
                top: 7px;
                left: 7px;
            }
    
             .remove-image:hover {
                background: #c9291e;
            }
            .imageBlogg{
                position: relative;
            }
    
             input[type="file"] {
                width: 30%;
                padding: 0.5rem;
                border-radius: 10px;
                border: 1px solid #ccc;
                margin-bottom: 1rem;
            }
    
             input[type="text"] {
                width: 50%;
                color: #000;
                margin-bottom: 2rem;
            }
            .form-switch {
                padding: 11px 37px;
            }
             .ck-editor__editable {
                min-height: 25vh;
                padding-bottom: 23px;
            }
        }
       
        .addBlogBtn {
            display: flex;
            justify-content: flex-end;
            .submit {
                background: $button;
                color: $white;
                border: 0;
                box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, .4);
                font-size: 14px;
                max-height: 32px;
                line-height: 20px;
                font-weight: 500;
    
                &:hover {
                    border: 0;
                    transform: translateY(-1px) !important;
                    background: #05304A;
                }
           
            }
        }

        .error {
            color: #f44336;
            text-align: center;
            font-size: 1.2rem;
            font-weight: bold;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }

}