@import "../src/styles/auth";
@import "../src/styles/variable";
@import "../src/styles/header";
@import "../src/styles/roles";
@import "../src/styles/blog";
@import "../src/styles/project";
@import "../src/styles/dashboard";
@import "../src/styles/systemUser";
@import "../src/styles/faq";
@import "../src/styles/memberRequest";
@import "../src/styles/dicmember";
@import "../src/styles/pageContent";
@import "../src/styles/pageResponsive";
@import "../src/styles/sufti_modal";
@import "../src/styles/datatable";
@import "../src//styles/projectDetails";
@import "../src//styles/walletconnect";
@import "../src/styles/serviceprovider";

body {
    font-family: $rubic;
    font-weight: normal;
}