.connectWallet-popup {
    display: flex;
    align-items: center;
}

.connectWallet-popup .modal-content {
    background: #053863;
}

.connectWallet-popup .modal-content .modal-header {
    justify-content: center;
    flex-direction: column;
}

.connectWallet-popup .modal-content .modal-title {
    text-align: center;
    color: #fff;
}

.connectWallet-popup .modal-content .subtitle {
    text-align: center;
    color: #fff;
    font-size: 14px;
    margin-top: 16px;
}

.connectWallet-popup .modal-content .read-more {
    color: #fff;
}

.connectWallet-popup .connectWallet-list {
    list-style-type: none;
}

.connectWallet-popup .connectWallet-list .list-item {
    padding: 8px 0px;
    color: #fff;
    display: flex;
    gap: 16px;
    cursor: pointer;
}