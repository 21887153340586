@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;500;600;800;900&display=swap');
$primary: #0F172A;
$white : #fff;
$secondary :#05304A;
$button : #06487E;
$buttonh : #396D8D;
$lightButton : #E2E8F0;
$border: #CBD5E1;
$placeholder : #94A3B8;
$icon:#94A3B8;
$gold:#ECC2A8;

$text : #94A3B8;
$body : #EEF2F6;


/* fonts /*/

$rubic: 'Rubik', sans-serif;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-large: 900;

/* radius */

$radius8 : 8px;