.rigthcontent .right-tab {
  padding: 20px;
  background: #dddddd;
}


.image-container {
  margin-top: 20px;
  max-height: 400px;
  overflow: "auto";
}
/* productBox */
.productBox {
  border:1px solid #CBD5E1;
  padding: 10px;
  border-radius: 12px;
}

/* end */

.kyc-docs .pg-viewer-wrapper {
  overflow-y: hidden;
}

.kyc-docs {
  max-height: calc(100vh - 130px);
  overflow-y: scroll;
}

.modal-footer .btn-reject{
  background-color: #e65b65;
}
.modal-footer .btn-reject:hover{
  background-color: #e65b65;
}


#pagination-last-page .MLcXO {

  height: auto;
  width: auto
}

button.btn-mr-10 {
  margin-right: 10px;
}
button.btn-accept {
  background: #032744;
}
button.btn-accept:hover {
  background: #032744;
}
button.btn-cancel{
  background-color: #e65b65;
  border-color: #e65b65;
}
button.btn-cancel:hover{
  background-color: #e65b65;
  border-color: #e65b65;
}
.container-style .class-style {
  border: none;
  box-shadow: unset;
  margin-top: 0px !important;
}

.container-style .class-style .title-style {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: 500;
  border-bottom: 1px solid gray;
  padding-bottom: 10px;
  width: fit-content;
}

.container-style .class-style .title-style .p-style {
  font-size: 16px;
  margin-bottom: 10px;
}

.custom-tab {
  width: 100%; /* Adjust the width as needed */
}

.custom-tabs-container {
  position: relative; /* Ensure the container is positioned */
}

/* GoldDetails.css */

.gold-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #fff;
}

.address-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #f8f9fa;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.detail-text {
  font-size: 16px;
  margin-bottom: 10px;
}


/* for Tabs in prject detail */
.tabs-container {
  padding-top: 20px;
}

.project-detail-tab-sec .tab-content {
  margin-top: 0;
  background: #fff;
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 195px);
  flex: 0 0 auto;

}

.custom-tabs {
  background-color: #f0f0f0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative; /* Ensure the tabs are positioned relative to the container */
}

.custom-tab {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.custom-tab-content {
  padding: 20px;
  position: absolute; /* Position tab content absolutely */
  top: 100%; /* Position tab content below the tabs */
  left: 0; /* Align tab content to the left */
  z-index: 1; /* Ensure tab content is displayed above the tabs */
  background-color: #fff; /* Optional: Set background color for tab content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add box shadow for tab content */
}


// .custom-tab:hover {
//   background-color: #e0e0e0;
// }

.custom-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* for Card Section on Projct Details */

.userNameBlock {
  padding: 10px;
}

.profileDetail {
  display: flex;
  // justify-content: space-between;
  align-items: center;
}
#project-tabs-tabpane-discription .tab-content,#project-tabs-tabpane-images .tab-content,
#project-tabs-tabpane-documents .tab-content,#project-tabs-tabpane-investmentdetails .tab-content,
#project-tabs-tabpane-golddetails .tab-content, #project-tabs-tabpane-defaulttemplates .tab-content{
  width: 100%;
}
#project-tabs-tabpane-discription .tab-content .tabSectionMain ul{
  padding: 10px 10px 10px 0px;
  border: none;
}
.userDetail label {
  margin-right: 10px;
}

.projectVal {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 3.6px;
  /* Adjust based on your design needs */
}


.projectNameContainer {
  word-wrap: break-word;
  /* Allow long words to be broken and wrap onto the next line */
}

.userDetail {
  display: flex;
  flex-wrap: wrap;
  /* Wrap labels onto the next line if there's not enough space */
}

.userDetail label {
  margin-right: 10px;
  margin-bottom: 5px;
  /* Adjust the bottom margin between labels */
}

/* added css */
.tabSectionMain {
  padding: 0px;
  position: relative;
  .leftMenu{
    overflow: auto;
    max-height: 400px;
    border-right: solid 1px #ccc;
  }
  ul{
    padding: 20px;
    border-radius: 10px;
  }
  li {
    position: relative;
    .nav-item {
      margin-bottom: 10px !important;
     
  }
    button {
      width: 100% !important;
      text-align: left !important;
      padding:12px;
      border-radius: 0;
      color: inherit;
    }
 
  .nav-link{
    border: 0px solid #ccc;
    margin-bottom: 4px;
    border-radius: 0px;
    color:rgb(15 23 42 / 42%);
    
  }
  .active{
    border: 0px solid #c3ab7d !important;
    border-radius: 4px;
    color: #fff;
    &::after
    {
      // content: "\f061";
      position: absolute;
      right: 0;
    }
  }
  }
 .contentBoxRight{
  padding-top: 16px;
 }

}

.mt-20 {
  margin-top: 20px;
}

.boxDetails {
  border: solid 1px #ccc;
  padding: 24px;
  border-radius: 16px;
  max-width: 90%;
  margin-top: 20px;
  background: #fff;

  h3 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 10px;
  }
}
.project-detail-tab-sec .tab-content .tab-content{
  width: 100%;
  margin-top: 0;
}
ul.custom-tabs.nav.nav-tabs {
  background: no-repeat;
  white-space: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 5px;
  display: inline-block;
  margin-right: 20px;
  width: 170px;
  flex: 0 0 auto;
  min-width: 170px;
}
.locaton-address-li {
  max-width: 295px;
}
ul.custom-tabs.nav.nav-tabs li{
  border: unset;
}
.nav-tabs .nav-link {
  font-weight: 700;
  color: #0f172ab0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #c3ab7d !important;
  text-align: left;
}

.nav-link:focus,
.nav-link:hover {
  color: #c3ab7d !important;
}

.container-style .class-style{
  padding: 0px;
}
.artdetail-row h4 {
  text-transform: capitalize;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
  width: fit-content;
  font-size: 20px;
}
.artdetail-row ul{
  list-style: none;
  margin-bottom: 0px;
}

 .video-container .loading-container .loading{
  margin-left: 300px;
 }

@media screen and (max-width: 768px) {
.tab-inner-col{
  flex: 0 0 auto;
  width: 100% !important;
}
  .project-detail-tab-sec .tab-content {
      width: 100%;
      margin-top: 20px;
  }
  .locaton-address-li {
    max-width: 100%;
}
.artdetail-row ul {

  padding-left: 0;
}
.tabSectionMain .leftMenu{
  border: unset;
  border-bottom: 1px solid #ccc;
}

}