.pdf-viewer-modal .modal-content{
    width: 700px;
}

.userDetail .form-text{
    margin-top: 0px;
}

.modal-dialog .modal-content{
    // width: 750px;
    width: 100%; /* Adjust the width as per your requirements */
    margin-left: auto;
    margin-right: auto;
}